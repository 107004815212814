<template>

  <el-dialog
      title="查看"
      v-model="performanceinfo"
      width="40%"
      :close-on-click-modal = "false"
      height="200px"
      center append-to-body @close='clearallinfo'>
    <div class="el-dialog-div">
      <el-form ref="searchForm"   label-width="240" :inline="true">
        <el-form-item label="综合查询:" prop="name"><el-input v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input></el-form-item>
        <el-form-item><el-button type="primary" @click="queryMembers()">搜索</el-button></el-form-item>
      </el-form>
      <el-table :data="dataList" border  ref="multipleTable">
        <el-table-column prop="bruser_id" label="ID" ></el-table-column>
        <el-table-column prop="bruser_name" label="用户名" ></el-table-column>
        <el-table-column prop="mobile" label="手机号码"></el-table-column>
        <el-table-column fixed="right" label="操作" width="
        100px" >
          <template v-slot="scope"  >
            <el-button  icon="el-icon-edit" @click="closeDialog(scope.row)" type="success" size="small">确定</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,sizes,prev,pager,next,jmper"
        :total="totalCount">
    </el-pagination>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>

  </el-dialog>

</template>

<script>


export default {
  created () {
    this.performanceinfo = true;
    this.handleClickQuery();
  },


  data(){
    let self=this;
    return{
      dataList:[],
      position:'top',
      performanceinfo:'',
      ruleForm:{
        id:this.$parent.id,
      },
      tableDataName:'',     //查询条件
      totalCount:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
    }},

  methods: {

    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchBrUser";
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;

      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
       // alert(JSON.stringify(res));
        this.dataList=res.datas.items;

        this.totalCount=res.datas.totalCount;
      }, null, this);
    },

    //条件查询的方法
    queryMembers:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "searchBrUser";
      req.synthesize_params=this.tableDataName;

      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {

        this.dataList=res.datas.items;
        this.totalCount=res.datas.totalCount;
      }, null, this);
    },

    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
    closeDialog: function(item){
      if(item.isTrusted){
        this.$emit('closec');
      }
      else{
        this.$emit('closec',item); //通知父组件改变。
      }

      this.clearinfo();
    },

    clearinfo:function(){
      this.performanceinfo = false;
    },
	clearallinfo:function(){
		this.$emit('closec');
      this.performanceinfo = false;
    },

  },


  mounted(){

  },
  components: {

  }
}
</script>

<style scoped>
.el-dialog-div {
  height: 30vh;
  overflow: auto;
  Margin:5px;
}
</style>
<!--会员管理数据列表-->
<template>
  <div>
  <el-form ref="searchForm"   label-width="240" :inline="true">
    <el-form-item ><el-button   @click="exportExcel()">导出</el-button></el-form-item>
    <el-form-item label="综合查询:" prop="name"><el-input v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input></el-form-item>
    <el-form-item label="选择项目:" prop="name">
      <el-select v-model="article" placeholder="请选择" style="width:240px" clearable>
        <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="会员类型:" prop="name">
      <el-select v-model="mold" placeholder="请选择" style="width:240px" clearable>
        <el-option  v-for="data in options" :key="data.value" :label="data.label" :value="data.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item><el-button  type="primary" @click="queryMembers()">搜索</el-button></el-form-item>

    <el-form-item><el-button type="danger" @click="reset()" plain>重置</el-button></el-form-item>
  </el-form>

  <el-table :data="dataList" border  ref="multipleTable">
    <el-table-column prop="id" label="ID" ></el-table-column>
    <el-table-column prop="user_name" label="用户名" ></el-table-column>
    <el-table-column prop="plot_name" label="所属项目"></el-table-column>
    <el-table-column prop="mobile" label="手机号码"></el-table-column>
    <el-table-column prop="user_sex" label="用户性别" >
            <template v-slot="scope">
              <el-tag
                  :type="scope.row.user_sex==1?'success':scope.row.user_sex==2?'danger':'warning'"
                  close-transition>{{scope.row.user_sex==0?'男':scope.row.user_sex==1?'女':'未知'}}</el-tag>
            </template>
    </el-table-column>
    <el-table-column prop="user_imgs" label="用户头像" >
      <template width="60" v-slot="colum" style="width:55px" >
        <el-image style="width:50px;height:50px;border:none; border-radius:70%" :src="colum.row.user_imgs" />
      </template>
    </el-table-column>
    <el-table-column prop="deptName" label="所属部门"></el-table-column>
	<el-table-column prop="positionName" label="员工类别"></el-table-column>
	
    <el-table-column prop="login_num" label="登录次数"></el-table-column>
    <el-table-column prop="last_login_time" label="上次登录时间"></el-table-column>
    <el-table-column prop="real_name" label="真实姓名"></el-table-column>
    <el-table-column prop="status" label="用户状态" :formatter="forstate">
      <template v-slot="scope">
        <el-button size="mini" :type="scope.row.status=='0'?'danger':'success'" @click="handleIsDisplay(scope.$index, scope.row)">
          {{scope.row.status=='0'?'禁用':'开启'}}
        </el-button>
      </template>
    </el-table-column>
     <el-table-column prop="manage" label="会员类型" :formatter="formanage" ></el-table-column>
	 <el-table-column prop="bruser_name" label="关联巡检人员姓名"></el-table-column>
    <el-table-column fixed="right" label="操作" width="100" >
      <template v-slot="scope">
        <el-button  icon="el-icon-edit"    @click="Modify(scope.row)" type="success" size="small">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
    <el-config-provider :locale="locale">
      <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="total,sizes,prev,pager,next,jmper"
            :total="totalCount">
      </el-pagination>
    </el-config-provider>
  <rms_c_members_list @closeMembrane="closeMembrane"  v-if="membersDialogVisible"></rms_c_members_list>
  </div>
</template>
<script>
import rms_c_members_list from './rms_c_members_info.vue';
import { reactive, ref, toRefs } from 'vue';
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
    this.getplotname();
  },

  methods: {
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchMembers";
	  req.synthesize_params=this.tableDataName;
	  req.plot_id =this.article;
	  req.manage =this.mold;
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.dataList=res.datas.items;
        this.totalCount=res.datas.totalCount;
      }, null, this);
    },

    Modify: function(item) {
      this.id = item.id+"";
      this.realname=item.real_name+"";
      this.birthday=item.birthday+"";
      this.plotname=item.plot_name+"";
      this.plotid=item.plot_id+"";
      this.status = item.status+"";
      this.mod = 'edit';
      this.manage=item.manage+"";
	  this.position_id = item.position_id || 0;
	  this.dept_id = item.dept_id || 0;

      this.membersDialogVisible = true;
    },
    closeMembrane:function(){
      this.id = '';
      this.mod = '';
      this.membersDialogVisible = false;
      this.handleClickQuery();
    },
    //查询所有项目的方法
    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getPmsName";
      redata.id=this.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionsype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    //条件查询的方法
    queryMembers:function(){
        this.dataList = [];
        var req = {};
        req.cmd = "searchMembers";
        req.synthesize_params=this.tableDataName;
        req.plot_id =this.article;
        req.manage =this.mold;
        req.start=(this.currentPage-1) * this.pagesize;
        req.count=this.pagesize;
        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
          console.log(res);
          this.dataList=res.datas.items;
          this.totalCount=res.datas.totalCount;
        }, null, this);
    },
    //导出EXCEK的方法
    exportExcel:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "MemberExcel";
      req.synthesize_params=this.tableDataName;
      req.plot_id =this.article;
      req.manage =this.mold;
      this.$sknet.download(this.$skconf("met.surl"), req)
      this.handleClickQuery();
    },
    reset:function(){
      this.mold='';
      this.article='';
      this.tableDataName='';
      this.handleClickQuery();
    },

    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
/*    //根据返回值判断
    formatter:function(row,colum){
      const sex={
        0:'未知',
        1:'男',
        2:'女',
      }
      return  sex[row.user_sex];
    },*/

    forstate:function(row,colum){

      const state={
        0:'禁用',
        1:'开启',
      }
      return  state[row.status];
    },

    formanage:function(row,colum){
      const state={
        0:'普通人员',
        1:'服务人员',
        2:'维修人员',
        3:'工程经理',
	4:'项目管理人员'
      }
      return  state[row.manage];
    },
    handleIsDisplay:function($index,row){
      var req = {};
      req.cmd = "updateMembersStatus";

      req.id=row.id;
      if(row.status == 1){
        req.status=0;
      }
      else if(row.status == 0){
        req.status=1;
      }

      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.handleClickQuery();
      }, null, this);
    },

  },

  data() {
    let self=this;
    return {
      dataList:[],  //表格数据集
      membersDialogVisible: false,
      tableDataName:'',   //综合查询条件参数
      mold:'',           //会员类型查询条件参数
      optionsype:'',     //所属项目选项
      article:'',       //选择的所属项目
      user_sex:[{

      }],
      options:[         //会员类型的选项
        {
          value:'0',
          label:'业主',
        },
        {
          value:'1',
          label:'服务人员',
        },
        {
          value:'2',
          label:'维修人员',
        },
        {
          value:'3',
          label:'工程经理',
        },
        {
          value:'4',
          label:'项目管理人员',
        }
      ],
      totalCount:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页

    }
  },

  components: {
    rms_c_members_list,
    [ElConfigProvider.name]: ElConfigProvider,
  },

}


</script>
<template>

  <el-dialog
      title="编辑"
      v-model="membersinfo"
      width="30%"
      center append-to-body @close='closeDialog'>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
    <el-form-item label="真实姓名" prop="realname" :rules="[
      { required: true, message: '请输入', trigger: 'blur' },
    ]"
    >
      <el-input v-model="ruleForm.realname"></el-input>
    </el-form-item>
      <el-form-item label="出生日期" v-model="ruleForm.birthday">
         <el-date-picker
             validate-event="true"
             @change="handleDateChange"
            v-model="ruleForm.birthday"
            type="date"
            placeholder="请输入"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="所属项目" prop="optiontype" :rules="[
      { required: true, message: '请输入', trigger: 'blur' },
    ]"
      >
        <el-select   v-model="ruleForm.optiontype"  placeholder="请选择" @change="getDepts">
          <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会员类型" prop="optionpar" :rules="[
      { required: true, message: '请输入', trigger: 'blur' },
    ]"
      >
        <el-select    v-model="ruleForm.optionpar"  placeholder="请选择" @change="yincang">
          <el-option  v-for="data in options" :key="data.value" :label="data.label" :value="data.value">
          </el-option>
        </el-select>
      </el-form-item>
      <div >

      <el-form-item label="关联人" prop="picture"  v-if="Affiliate" >
        <el-button   @click="info()">选择</el-button>
      </el-form-item>
        <el-form-item label="关联人名id" prop="relevance" v-if="false">
          <el-input :readonly="true"   v-model="ruleForm.bruser_id"></el-input>
        </el-form-item>
      <el-form-item label="关联人名" prop="bruser_name" v-if="Affiliate" :rules="[
      { required: false, message: '请添加', trigger: 'blur' },
    ]">
        <el-input :readonly="true"   v-model="ruleForm.bruser_name" ></el-input>
      </el-form-item>
     </div>
    <el-form-item label="会员状态" prop="status" :rules="[
      { required: true, message: '请选择状态', trigger: 'blur' },
    ]"
    >
      <el-radio-group  v-model="ruleForm.status">
        <el-radio :label="1"  border>开启</el-radio>
        <el-radio :label="0" border>禁用</el-radio>
      </el-radio-group>
    </el-form-item>
	<el-form-item label="所属部门" prop="optiondept">
	    <el-select   v-model="ruleForm.optiondept"  placeholder="请选择">
	      <el-option v-for="data in optiondepts" :key="data.id" :label="data.name" :value="data.id">
	      </el-option>
	    </el-select>
	</el-form-item>
	<el-form-item label="职位" prop="optiondic2">
	      <el-select   v-model="ruleForm.optiondic2"  placeholder="请选择">
	        <el-option v-for="data in optiondic2s" :key="data.id" :label="data.name" :value="data.id">
	        </el-option>
	      </el-select>
	</el-form-item>
    </el-form>
      <div class="ucForm-group-ad">
        <div class="ucForm-group ucBtn-bg">
          <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit('ruleForm')"><i class="iImg"></i>确定</button>
          <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog"><i
              class="iImg"></i>返回</button>
        </div>
      </div>
      <div class="clear"></div>
  </el-dialog>
  <rms_member_user_add @closec="closememberuserM"  v-if="memberuserMDialogVisible"></rms_member_user_add>

</template>



<script>

import rms_member_user_add from './rms_member_user_add.vue'



export default {

  created () {
	var self = this;
    self.membersinfo = true;
    self.getplotname();
	self.getdics(self.ruleForm.plotid);
    self.clearinfo();
    self.yincang();
    self.handleClickQuery();
  },

  data:function(){

    //let self=this;
    return{
      Affiliate:false,       //关联人是否显示标识
      memberuserMDialogVisible:false,
	  
	  optiondepts:[], //员工所属部门
	  optiondic2s:[], //员工职位
	  
      ruleForm:{
        status: parseInt(this.$parent.status),
        optionpar:this.$parent.manage,   //为会员类型设置下拉框默认值
        description:"",
        ids:this.$parent.id,
        realname :this.$parent.realname,
        birthday:this.$parent.birthday,
        manage:this.$parent.manage,
        optiontype:this.$parent.plotname,             //为下拉框得默认值
        plotid:this.$parent.plotid,

		openid: this.$parent.openid,
		optiondept:this.$parent.dept_id,
		optiondic2:this.$parent.position_id,
		
        optionsype:'',
        membersinfo:false,
        members:false,
		relevance:null
      },
      options:[                 //定以下拉框的值
        {
          value:'0',
          label:'普通人员',
        },
        {
          value:'1',
          label:'服务人员',
        },
        {
          value:'2',
          label:'维修人员',
        },
        {
          value:'3',
          label:'工程经理',
        },
        {
          value:'4',
          label:'项目管理人员',
        }

      ],
    }},

  methods: {
    /*查询系统用户和会员表的关联信息*/
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchBrUserByMemberId";
      req.id=this.ruleForm.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
		if(res.datas.items.length > 0){
			this.ruleForm.bruser_name=res.datas.items[0].bruser_name;
			this.ruleForm.bruser_id=res.datas.items[0].bruser_id;
		}
       
	
      }, null, this);
    },
    commit:function(ruleForm){
      this.$refs[ruleForm].validate((valid) => {
        if(valid){
          var self = this;
          var data = {};
          data.cmd = "updateMembers";
          data.id=this.ruleForm.ids;
          data.real_name=this.ruleForm.realname;
          data.birthday=this.ruleForm.birthday;
          data.bruser_id=this.ruleForm.bruser_id;
          //根据传的值来判断是否传递id
          if(this.ruleForm.optiontype==this.$parent.plotname){
            data.plot_id=this.ruleForm.plotid;
          }
          else{
            data.plot_id=this.ruleForm.optiontype;
          }
          data.manage=this.ruleForm.optionpar;
          data.status=this.ruleForm.status;
		  
		  if(this.ruleForm.optiondept != null && this.ruleForm.optiondept > 0){
			  data.dept_id = this.ruleForm.optiondept;
		  }
		  if(this.ruleForm.optiondic2 != null && this.ruleForm.optiondic2 > 0){
			data.position_id = this.ruleForm.optiondic2;
		  }
          // alert(JSON.stringify(data));
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
            data.reason;
            self.$message("修改成功");
            self.closeDialog();
          }, function(data) {
            self.$message(data.reason);
          }, self);
        }
        else {

        }
      })

    },
    /*是否显示添加人员配对的方法*/
    yincang:function(){

      if(this.ruleForm.optionpar !=0){
        this.Affiliate=true;
      }
      else{
        this.Affiliate=false;
      }

    },

    closeDialog: function(){
      this.members = false;
      this.clearinfo();
      this.$emit('closeMembrane'); //通知父组件改变。
    },
    clearinfo:function(){
      this.description = "";
    },
    //查询所属项目的访问接口

    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getPmsName";
      redata.id=this.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
      this.optionsype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
	getDepts:function(data){
		this.ruleForm.optiondept = 0;
		this.ruleForm.optiondic2 = 0;
		this.getdics(data);
	},
	//获取部门和职位字典
	getdics:function(plotId){
		var self = this;
	    var redata = {};
	    redata.cmd = "selectRmsDictionaryTablePc";
	    redata.plot_id = plotId;
	    this.$sknet.postJson(this.$skconf("met.surl"), redata, function(res){
			if(res.result == "OK"){
				self.optiondepts = res.datas.deptList;
				self.optiondepts.unshift({id:0, name:"无"});
				self.optiondic2s = res.datas.wxzts;
				self.optiondic2s.unshift({id:0, name:"无"});
			}
		
	    }, function(data) {
			self.$message(data.reason);
		}, self);
	},

    /*跳转编辑页面的方法*/
    info: function() {
      // if(this.ruleForm.bruser_name!=null){
      //   var self=this;
      //   self.$message("无法添加");
      // }
      // else{
        this.memberuserMDialogVisible = true;
      // }
    },

    // 时间选择非空验证
    handleDateChange:function(){

    },
    closememberuserM:function(data){

      if(data==undefined){
        this.memberuserMDialogVisible  = false;
      }
      else{
        this.ruleForm.bruser_name=data.bruser_name;
        this.ruleForm.bruser_id=data.bruser_id;
        this.memberuserMDialogVisible  = false;
      }

    },


  },


  mounted(){

  },
  components: {
    rms_member_user_add,

  }
}
</script>

<style scoped>

</style>